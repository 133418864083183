/* MainTenant.css */

/* Add some styles to the table */
.containerMainPage{
  background-color: #FCFFE7;
  height: 150vh;
}
#pdf-table {
  width: 50%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-left: 25%;
}

/* Style the table header */
#pdf-table th {
  background-color: #A0153E;
  color: white;
  border: 1px solid #dddddd;
  padding: 12px;
  text-align: center;
}

/* Style the table body */
#pdf-table td {
  border: 1px solid #dddddd;
  padding: 12px;
  text-align: center;
}

/* Alternate row color for better readability */
#pdf-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Style the "NO HARVEST" message */
#pdf-table td[colspan="4"] {
  text-align: center;
  font-style: italic;
  color: #888888;
}

.button-main{
  margin-left: 30.2%;
  margin-top: 15%;
}

.date-pdf{
  margin-left: 43.5%;
}

/* Style the buttons */
.containerMainPage button {
  background-color: #EB455F;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.containerMainPage button:hover {
  background-color: #005f75;
}

.containerMainPage h1{
  color: black;
}

.button-main1{
  margin-left: 4%;
  margin-top: 15%;
}

.containerMainPage select {
  background-color: #5D0E41;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 40%;
  margin-left: 2%;
}

.containerMainPage select:hover {
  background-color: #5D0E41;
}

@media only screen and (max-width: 375px) {
  .date-pdf{
    margin-left: 20%;
  }

  .button-main{
    margin-left: 24%;
  }

  #pdf-table{
    margin-left: -1%;
  }

  .containerMainPage{
    background-color: #FCFFE7;
    height: 250vh;
  }




}