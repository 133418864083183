body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
    /* Light background color */
    color: #495057;
    /* Text color */
}

header {
    background-color: #343a40;
    /* Dark background color for the header */
    color: #fff;
    /* Text color for the header */
    text-align: center;
    padding: 20px;
}

section {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    color: white;
    font-size: 20px;

    /* Background color for the content section */
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

section h2 {
    color: white;
    text-align: center;
    font-size: 45px;
    /* Dark text color for headings */
}

p {
    margin-bottom: 20px;
}

.aboutP {
    text-align: justify;
}

.team-member {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.team-member img {
    border-radius: 50%;
    margin-right: 10px;
}

.Background  {
    background-color: #495057;
}

.contact-info {
    margin-top: -30px;
    text-align: center;
    background-color: #495057;
}

.contact-info p {
    font-size: 20px;
}

.contact-info a {
    font-size: 20px;
}

.wholecontainer {
    max-width: 1350px;
    margin: 0 auto;
    padding: 20px;

    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

h1,
.wholecontainer h2 {
    color: white;
    font-size: 50px;
    text-align: center;
}

p {
    line-height: 1.6;
}

.team-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.team-member {
    width: 400px;
    background-color: white rgba(0, 0, 0, 0.4);
    border: 2px solid #ddd;
    border-radius: 8px;
    margin: 15px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

.team-member:hover {
    transform: scale(1.05);
}

.team-member img {
    width: 40%;
    height: auto;
    border-bottom: 1px solid #ddd;
}

.member-info {
    padding: 15px;
    color: white;
    font-size: 15px;
}

.member-info p {
    font-size: 17px;
}

.backgroundPicture {
    background-image: url(../img/Onions.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 150vh;
}

@media only screen and (max-width: 375px) {

    .backgroundPicture {
        background-image: url(../img/Onions.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 290vh;
    }

    .Background{
        height: 40vh;
    }




}