body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg, #58555a);
}
main{
    background: linear-gradient(135deg, #393E46, #58555a);
}
.create-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.create-account-form {
  max-width: 400px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.create-account-header {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 14px 20px;
  border-radius: 6px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  border-color: #667eea;
  outline: none;
}

.btn-create {
  width: 100%;
  padding: 12px 20px;
  border-radius: 6px;
  background-color: #393E46;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-create:hover {
  background-color: #764ba2;
}

.login-link {
  margin-top: 20px;
  text-align: center;
}

.login-link a {
  color: #393E46;
  text-decoration: none;
  transition: color 0.3s ease;
}

.login-link a:hover {
  color: #764ba2;
}
