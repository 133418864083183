body {
    font-family: Arial, sans-serif;
    color: black;

}

/* Style the tab container */
.tab-container1 {
    display: flex;
    align-content: center;
    justify-content: right;
    opacity: 80%;
    background-color: gray;
    padding: 20px 50px;
    color: black;

}

/* Style the tab buttons */
.Main-tab {
    cursor: pointer;
    padding: 20px 20px;
    border: none;
    place-items: center;
    border-radius: 5px;
    border-spacing: 50px;
    margin-left: 10px;
    background-color: #FCFFE7;
    color: white;
    transition: background-color 0.3s;

}

.Main-tab1 {
    cursor: pointer;
    padding: 20px 20px;
    border: none;
    place-items: center;
    border-radius: 5px;
    border-spacing: 50px;
    margin-left: 10px;
    background-color: #FCFFE7;
    color: white;
    transition: background-color 0.3s;

}



.Main-tab2 {
    cursor: pointer;
    padding: 20px 20px;
    border: none;
    place-items: center;
    border-radius: 5px;
    border-spacing: 50px;
    margin-left: 10px;
    background-color: #FCFFE7;
    color: white;
    transition: background-color 0.3s;

}

.Main-tab3 {
    cursor: pointer;
    padding: 20px 20px;
    border: none;
    margin-left: 10px;
    place-items: center;
    border-radius: 5px;
    border-spacing: 50px;
    background-color: #555;
    color: white;
    transition: background-color 0.3s;

}

/* Change background color on hover */
.Main-tab:hover {
    background-color: #2B3467;
    color: white;
}

.Main-tab1:hover {
    background-color: #2B3467;
    color: white;
}

.Main-tab2:hover {
    background-color: #2B3467;
    color: white;
}

.Main-tab3:hover {
    background-color: #2B3467;
    color: white;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}

.Main-tab a {
    text-decoration: none;
    color: black;
}

.Main-tab1 a {
    text-decoration: none;
    color: black;
}

.Main-tab2 a {
    text-decoration: none;
    color: black;
}

.Main-tab3 a {
    text-decoration: none;
    color: black;
}

.logo-navbar {
    width: 5%;
    padding: 0%;
    margin-right: 81%;


}

.Main-tab:hover a {
    text-decoration: none;
    color: white;
}

.Main-tab1:hover a {
    text-decoration: none;
    color: white;
}

.Main-tab2:hover a {
    text-decoration: none;
    color: white;
}

.Main-tab3:hover a {
    text-decoration: none;
    color: white;
}

.NAVMAIN{
    display: contents;
}


@media only screen and (max-width: 375px) {

    /* .tab-container1{
        margin-left: 10%;
    } */
    /* .Main-tab{
        margin-left: 70%;
    } */



}