@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200&family=Space+Mono&display=swap" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=Tillana:wght@400;600&display=swap');
:root{
    --space-mono-font: 'Space Mono', monospace;
    --border-dark-subtle: #373838; 
    --font-tillana:'Tillana', cursive;
}
*{
    box-sizing: border-box;
}
body *{
    font-family: var(--space-mono-font);
}
.font-tillana{
    font-family: var(--font-tillana);
}
/**
Page Design
*/
body,
html{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}
body{
    background-color: #F9F9F9;
}
a{
    padding-top: 2em;
    padding-bottom: 2em;
}

#content-wrapper>section:not(:nth-child(1)){
padding-top: 2em;
padding-bottom: 2em;
}
#content-wrapper>section:nth-child(odd){
    background-color: #67778b;
}
#content-wrapper>section:nth-child(odd) *{
    color: #fff;
}

/* Top Navigation Custom Design */
#topNavigation{
    background-color: #67778b;
    z-index: 1023;
}
#topNavigation *{
    color:#fff;
    font-family: var(--font-tillana);
    font-weight: 600;
    font-size: .8rem;
}

/* Slider Section */
#carouselExample {
    position: relative;
    height: 80vh;
}
#carouselExample>.carousel-inner,
#carouselExample>.carousel-inner>.carousel-item{
    height: 100%;
}

#carouselExample>.carousel-inner>.carousel-item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    filter: brightness(0.7);
}
#carouselExample>.carousel-inner>.carousel-item .carousel-caption{
    bottom: unset;
    top: 0;
    height: calc(100%);
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#carouselExample>.carousel-inner>.carousel-item .carousel-caption *{
    text-shadow: 0px 0px 3px #373838;
}

#gallery-categories{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
#gallery-categories > .btn{
    margin:auto .5em;
}
#gallery-categories .btn.active{
    background-color: var(--bs-primary);
    border-color: var(--bs-primary) !important;
    color: var(--bs-light) !important;
}
#gallery-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
#gallery-container .gallery-item{
    width: 350px;
    height: 200px;
    background-color: #383838;
    box-shadow: 2px 2px 10px #6c6c6c;
    margin-top:1em;
    transition: all .3s ease-in-out;
    overflow: hidden;
    animation:showGalleryItem .3s ease-in-out forwards;
}
#gallery-container .gallery-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
#gallery-container .gallery-item.hide{
    animation:hideGalleryItem .3s ease-in-out forwards;
}
#gallery-container .gallery-item.hide *{
    scale: 0;
}
@keyframes hideGalleryItem {
    0%{
        width: 350px;
        height: 200px;
    }
    50%,100%{
        width: 0px;
        height: 0px;
    }
}
@keyframes showGalleryItem {
    0%{
        width: 0px;
        height: 0px;
    }
    50%,100%{
        width: 350px;
        height: 200px;
    }
}
#gallery-container .gallery-item:empty{
    position: relative;
}
#gallery-container .gallery-item:empty::before{
    content:"350 x 200";
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c7c7c7;
    font-size: 1.5rem;
    font-weight: bolder;
    letter-spacing: 2px;
}
/* Testimonies */
div#testimonies {
    position: relative;
    width: 500px;
    margin: auto;
}
div#testimonies .testimonies-inner{
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
}
div#testimonies .testimonies-inner .testimony-item{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
}
div#testimonies .testimonies-inner .testimony-item.active{
    display: block;
}

#testimonies .testimony-msg{
    font-family: var(--font-tillana);
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
}
#testimonies .testimony-quote,
#testimonies .testimony-assignatory,
#testimonies .testimony-assignatory-sub{
    text-align: center;
    font-weight: 400;
    font-family: var(--font-tillana);
}
#testimonies .testimony-assignatory-sub{
    font-size: .8rem;
}
#testimonies .testimony-quote .material-symbols-outlined{
    font-size: 2.8rem;
    color: #c7c7c7;
}
#testimonies .testimony-item-prev,
#testimonies .testimony-item-next{
    position: absolute;
    top: 0;
    height: calc(100%);
    width: 50px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
#testimonies .testimony-item-prev{
    left: -50px;
}
#testimonies .testimony-item-next{
    right: -50px;
}
#testimonies .testimony-item-prev .testimony-item-prev-btn,
#testimonies .testimony-item-next .testimony-item-next-btn{
    width: 35px;
    height: 35px;
    padding: unset;
    border: 5px solid #36363624;
    border-right: unset;
    border-bottom: unset;
    background-color: transparent;
}
#testimonies .testimony-item-prev .testimony-item-prev-btn{
    rotate: -45deg;
}
#testimonies .testimony-item-next .testimony-item-next-btn{
    rotate: 135deg;
}
div#testimonies .testimonies-inner .testimony-item.slide-start.slide-next{
    display: block;
    animation:slideToLeft .3s ease-in-out forwards;
}
@keyframes slideToLeft {
    0%{
        transform: translateX(0%)
    }
    50%, 100%{
        transform: translateX(-100%)
    }
    
}
div#testimonies .testimonies-inner .testimony-item.slide-end.slide-next{
    display: block;
    animation:slideFromRight .3s ease-in-out forwards;
}
@keyframes slideFromRight {
    0%{
        transform: translateX(100%)
    }
    50%, 100%{
        transform: translateX(0%)
    }
}
div#testimonies .testimonies-inner .testimony-item.slide-start.slide-prev{
    display: block;
    animation:slideToRight .3s ease-in-out forwards;
}
@keyframes slideToRight {
    0%{
        transform: translateX(0%)
    }
    50%, 100%{
        transform: translateX(100%)
    }
    
}
div#testimonies .testimonies-inner .testimony-item.slide-end.slide-prev{
    display: block;
    animation:slideFromLeft .3s ease-in-out forwards;
}
@keyframes slideFromLeft {
    0%{
        transform: translateX(-100%)
    }
    50%, 100%{
        transform: translateX(0%)
    }
}


