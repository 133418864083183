body {
    font-family: Arial, sans-serif;
    

}

/* Style the tab container */
.tab-container {
    display: flex;
    align-content: center;
    justify-content: right;
    opacity: 80%;
    background-color: gray;
    padding: 20px 40px;
    color: black;

}

/* Style the tab buttons */
.Header-tab {
    cursor: pointer;
    padding: 20px 20px;
    border: none;
    place-items: center;
    border-radius: 5px;
    border-spacing: 50px;
    margin-left: 10px;
    background-color: #FCFFE7;
    color: white;
    transition: background-color 0.3s;

}

.Header-tab1 {
    cursor: pointer;
    padding: 20px 20px;
    border: none;
    place-items: center;
    border-radius: 5px;
    border-spacing: 50px;
    margin-left: 10px;
    background-color: #FCFFE7;
    color: white;
    transition: background-color 0.3s;

}

.Header-tab2 {
    cursor: pointer;
    padding: 20px 20px;
    border: none;
    place-items: center;
    border-radius: 5px;
    border-spacing: 50px;
    margin-left: 10px;
    background-color: #FCFFE7;
    color: white;
    transition: background-color 0.3s;

}

.Header-tab3 {
    cursor: pointer;
    /* padding: 20px 20px; */
    border: none;
    margin-left: 10px;
    place-items: center;
    border-radius: 5px;
    border-spacing: 50px;
    background-color: #555;
    color: #fff;
    transition: background-color 0.3s;

}

/* Change background color on hover */
.Header-tab:hover {
    background-color: #00224D;
}

.Header-tab1:hover {
    background-color: #00224D;
}

.Header-tab2:hover {
    background-color: #00224D;
}

.Header-tab3:hover {
    background-color: #00224D;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #5D0E41;
}

.Header-tab a {
    text-decoration: none;
    color: black;
}

.Header-tab1 a {
    text-decoration: none;
    color: black;
}

.Header-tab2 a {
    text-decoration: none;
    color: black;
}

.Header-tab3 a {
    text-decoration: none;
    color: black;
}

.Header-tab:hover a {
    text-decoration: none;
    color: white;
}

.Header-tab1:hover a {
    text-decoration: none;
    color: white;
}

.Header-tab2:hover a {
    text-decoration: none;
    color: white;
}

.Header-tab3:hover a {
    text-decoration: none;
    color: white;
}

.logo-navbar {
    width: 2%;
    padding: 0%;
    margin-right: 81%;


}