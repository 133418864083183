.container {
    background-color: #B0A695;
    background-size: cover;

}

body {
    background-color: #B0A695;
    background-size: cover;
    color: #B0A695;
    margin: 100%;
}



.login-container {
    display: flex;
    background-size: 400%;
    background-color: black;

    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;


}

.logo-container {
    background-color: #4285f4;
    padding: 20px;
    display: contents;
    align-items: center;
    justify-content: center;
}

.logo {
    position: absolute;
    top: 25%;
    left: 21%;
    max-width: 40%;
    height: auto;
}

.borderColor {
    border: 4px solid #B0A695;
}

.login-form {

    position: absolute;
    top: 27%;
    left: 53%;
    padding: 20px;
    width: 300px;
}

.login-header {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: black;
    color: white;
    opacity: 80%;
}

.form-group input[type="submit"] {
    background-color: #4285f4;
    color: #fff;
    cursor: pointer;
}

.form-group input[type="submit"]:hover {
    background-color: #3171e0;
}

.MAINPIC {
    width: 45%;
    margin-left: 15.4rem;
}

.BACKPICC {
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.login-header h2 {
    color: white;
}

.login-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid #393E46;
    /* Border color */
    color: white;
    /* Text color */
    background-color: #282c34;
    /* Background color */
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    margin-left: 45px;
}

/* Hover effect for the login button */
.login-button:hover {
    background-color: #25383C;
    color: white;
    border-color: green;
}

.login-button1 {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid #393E46;
    /* Border color */
    color: white;
    /* Text color */
    background-color: #282c34;
    /* Background color */
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    margin-left: 30px;
}

/* Hover effect for the login button */
.login-button1:hover {
    background-color: #25383C;
    color: white;
    border-color: red;
}

.create-account-link {
    display: block;
    text-align: center;
    margin-top: 0px;
    color: #fff; /* Text color */
    text-decoration: none;
    text-decoration: underline;
  }
  
  .create-account-link:hover {
    color: #61dafb; /* Hover color */
  }
  

  @media only screen and (max-width: 375px) {
    .BACKPICC{
        width: 100%;
        height: 135vh;
    }

    .logo{
        margin-left: -50%;
        max-width: 90%;
    }

    .login-header{
        margin-top: 50%;
        margin-left: -102%;
    }

    .form-group{
        margin-left: -45%;
        width:85%;
    }

    .login-button1{
        margin-left: -43%;
        width: 30%;
    }

    .create-account-link{
        margin-left: -101%;
    }

  }