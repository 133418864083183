footer{
 
    color: white;
    height: 200px;
    background-color: #00224D;
    margin-top: -64px;
}

.linkweb{
    margin-left: 18.3rem;
}

.linkweb1{
    margin-left: 1rem;
}

#copyright-year{
    margin-top: 60px;
}

@media only screen and (max-width: 375px) {
    footer{
        margin-top: 7%;
        height: 75vh;
    }

    .text-center{
        margin-top: 35%;
        font-size: 1rem;
    }

    #copyright-year{
        margin-top: 30%;
        font-size: 2rem;
    }
    .linkweb{
        margin-left: 0rem;
        margin-top: 20%;
        font-size: 1rem;
        text-align: justify;
    }
    
    .linkweb1{
        margin-left: 1rem;
        margin-top: 20%;
        font-size: 2rem;
        text-align: justify;
    }
    
    #copyright-year{
        margin-top: 60px;
        font-size: 2rem;
    }
}