.all-weights-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.button-container {
  text-align: center;
  margin-bottom: 20px;
}

.generate-pdf-btn {
  background-color: #4CAF50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.generate-pdf-btn:hover {
  background-color: #3e8e41;
}

.generate-pdf-btn:active {
  background-color: #3e8e41;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.btn-text {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.btn-icon {
  font-size: 18px;
  vertical-align: middle;
}

.selectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.size-selection,.month-selection {
  margin-right: 20px;
}

.label {
  font-size: 16px;
  margin-bottom: 10px;
}

.select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.result {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.result-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.result-value {
  font-size: 24px;
  font-weight: bold;
  color: #666;
}

.title {
  text-align: center;
}

.total-weight {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.total-weight p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
}