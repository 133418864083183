body {
    margin: 0;
    padding: 0;
}

.backpic {
    background-size: cover;

}

.homepage-thumbnail {
    position: absolute;
    left: 0;
    top: 0%;
    width: 100%;
    height: 100vh;
    background-position: center;
}

.row1 {
    display: flex;
    height: 88%;
    align-items: center;
}

.col1 h1 {
    position: absolute;
    top: 30%;
    left: 3.7%;
    font-family: 'Oswald', sans-serif;
    color: white;
    font-size: 150px;
}

.col1 p {
    position: absolute;
    top: 48%;
    left: 4.3%;
    font-family: 'Oswald', sans-serif;
    color: white;
    text-align: left;
    font-size: 30px;
    letter-spacing: 1.5px;
    line-height: 35px;
    width: 38%;
}

.col1 h4 {
    position: absolute;
    top: 44.2%;
    left: 32.3%;
    color: white;
}

.col1 {
    flex-basis: 50%;
}

.card1 {
    width: 250px;
    height: 290px;
    display: inline-block;
    border-radius: 10px;
    padding: 15px 85px;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 40px;
    margin-left: 25px;
    margin-bottom: 10px;
    background-image: url(../img/RedOnion.jpg);
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 61%;
    top: 17%;
    opacity: 70%;


}



.card2 {
    width: 250px;
    height: 290px;
    display: inline-block;
    border-radius: 10px;
    padding: 15px 85px;
    box-sizing: border-box;
    cursor: pointer;


    margin-bottom: 10px;
    background-image: url(../img/RedOnion1.jpg);
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 77.7%;
    top: 21%;
    opacity: 70%;

}



.card3 {
    width: 250px;
    height: 290px;
    display: inline-block;
    border-radius: 10px;
    padding: 15px 85px;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 40px;
    margin-left: 25px;
    margin-bottom: 10px;
    background-image: url(../img/RedOnion2.jpg);
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 61%;
    top: 48%;
    opacity: 70%;


}



.card4 {
    width: 250px;
    height: 290px;
    display: inline-block;
    border-radius: 10px;
    padding: 15px 85px;
    box-sizing: border-box;
    cursor: pointer;

    margin-top: 40px;
    margin-left: 25px;
    margin-bottom: 10px;
    background-image: url(../img/RedOnion3.jpg);
    background-position: center;
    background-size: cover;
    position: absolute;
    left: 76.5%;
    top: 48%;
    opacity: 67%;


}


@media only screen and (max-width: 375px) {
    .col1 h1 {
        font-size: 5em; 
        margin-top: -160px;
        margin-left: 13%;
      }

      .col1 h4{
        font-size: 1.5em; 
        margin-top: -180px;
        margin-left: -10%;
        
      }
    
    .col1 p {
        font-size: 0.9em;
        margin-top: -180px;
        margin-left: 26%;
        text-align: justify;
       }

       .card1{
        margin-top: 100%;
        margin-left: -56%;
        width: 20px;
       }
       .card2{
        width: 20px;
        margin-top: 90%;
        margin-left: -26%;
       }
       .card3{
        width: 20px;
        margin-top: 105%;
        margin-left: -56%;
       }
       .card4{
        width: 20px;
        margin-top: 105%;
        margin-left: -24%;
       }
    .backpic {
        background-size: cover;
    
    }
    .homepage-thumbnail {
        position: absolute;
        left: 0;
        top: 0%;
        width: 100%;
        height: 125vh;
        background-position: center;
    }
}