.account-page-container {
    margin: 20px;
  }
  
  .account-page-header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .account-page-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .account-page-table th, .account-page-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .account-page-table th {
    background-color: #f2f2f2;
  }
  
  .action-select {
    padding: 6px;
    font-size: 14px;
  }
  